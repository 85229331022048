import React from 'react';
import {Grid, Button} from '@mui/material';

export const CustomButton = (props) => {
  const { btnTitle, variant, color, btnStyles, customClass, onClickHandle } =
    props;

  return (
    <Grid>
      <Button
        variant={variant}
        color={color}
        sx={btnStyles}
        // onClick={onClickHandle}
        className={customClass}
        type="submit"
      >
        {btnTitle}
      </Button>
    </Grid>
  );
};
