import React from 'react';
import Header from '../../components/Header/Header'; 
import {CustomButton} from '../../components/Button/CustomButton';



function LeaveRequestTabledata() {

  return (
    <>
    <Header/>
      <section>
        <CustomButton btnTitle="Create Ad Hoc Leave"  btnStyles={{backgroundColor:"#d7d9db",float:'right',minWidth: 250,minHeight:50, margin:"20px"}} />
      </section>
    </>
  )
}

export default LeaveRequestTabledata