import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import StackNavigator from './components/Navigation/StackNavigator'
import 'bootstrap/dist/css/bootstrap.css';
import {oktaconfiguration} from "./configs/Oktaconfig";
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

let theme = createTheme({
  palette: {
    primary: {
      main: '#0076c7',
    },
    secondary: {
      main: '#FF2C00',
    },
  },
});

// okta configuration object initialization
const oktaAuth = new OktaAuth(oktaconfiguration)

function App() {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <ThemeProvider theme={theme}>
      <StackNavigator restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}/>
    </ThemeProvider>
  );
}

export default App;

