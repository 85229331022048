
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

 export default function RedirectLogin() {
    const { oktaAuth, authState } = useOktaAuth();

    const history = useHistory();

    useEffect(() => {
        if (!authState) {
            // When authState is undefined, Okta Auth SDK is still loading
            return;
        }

        if (!authState.isAuthenticated) {
            oktaAuth.signInWithRedirect();              // redirecting to login page if user not logged in 
        }
        else {
            // console.log(oktaAuth.getUser())
            history.replace('/leaverequests');          //redirecting to leaverequest page after successful login
        }


    }, [authState, oktaAuth]);


    if (!authState) {
        return <div>Loading...</div>;
    }

    return <>Redirecting.....</>;
};












