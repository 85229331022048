import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button  from '@mui/material/Button';
import { useOktaAuth } from '@okta/okta-react';


const Header = ()=>{
    const { oktaAuth, authState} = useOktaAuth();
    
    if(!authState){
        return <div>Loading......</div>
    }
    
    return(
        <Box position="static" >
        <AppBar position="static" sx={{bgcolor:'grey'}}>
            <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Logo
            </Typography>
            {oktaAuth.isAuthenticated && (<Button variant="contained" onClick={async()=>{await oktaAuth.signOut();}}>Logout</Button>)}
            <IconButton>
            
            <AccountCircleIcon fontSize='large'/>
            
            </IconButton>
            </Toolbar>
        </AppBar>
        </Box>
    )
}

export default Header;