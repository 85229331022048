import React from "react";
import {BrowserRouter,Route,Switch, Redirect} from "react-router-dom";
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import RedirectLogin from "../../pages/adhocleave/RedirectLogin";
import LeaveRequestTabledata from '../../pages/adhocleave/LeaveRequestTabledata'


const StackNavigator = (props) => {
    
 
  return (
    <>
    <BrowserRouter>
      <Security oktaAuth={props.oktaAuth} restoreOriginalUri={props.restoreOriginalUri}>
        <Switch>
            <Route path="/login/callback" component={LoginCallback}/>
            <Route path="/redirect" component={RedirectLogin}/>
            <SecureRoute path="/leaverequests" component={LeaveRequestTabledata}/>
            <Redirect from="/" to="/redirect"/>
        </Switch>
      </Security>
    </BrowserRouter>
  </>
  );
};

export default StackNavigator;

